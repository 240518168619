import { TableColumn } from "react-data-table-component";
interface ip {
  id: string;
  ip: string;
  userId: string;
}
interface UserType {
  email: string;

  id: string;

  password: string;
  isActive: boolean;
}
export const col: TableColumn<UserType>[] = [
  {
    name: "email",
    selector: (row) => row.email,
  },

  {
    name: "is Active",
    selector: (row) => `${row.isActive}`,
  },
  {
    name: "epic points",
    selector: (row) => `${row.password}`,
  },
];
