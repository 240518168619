import jwt from "jwt-decode";
const isUserLogedin = (): boolean => {
  const item = window.localStorage.getItem("token");
  if (item) {
    try {
      jwt(item);
      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};
const user = () => {
  const item = window.localStorage.getItem("token");
  if (item) {
    const data = jwt(item) as {
      username: string;
      email: string;
      isAdmin: boolean;
      isinf: boolean;
      text: string;
      img: string;
    };
    return data;
  }
};
const isUserAdmin = (): boolean => {
  const item = window.localStorage.getItem("token");
  if (item) {
    try {
      const data = jwt(item) as {
        username: string;
        email: string;
        isAdmin: boolean;
        isinf: boolean;
        text: string;
        img: string;
      };
      console.log(data);
      return data.isAdmin;
    } catch (error) {
      return false;
    }
  }
  return false;
};
const isUserInf = (): boolean => {
  const item = window.localStorage.getItem("token");
  if (item) {
    try {
      const data = jwt(item) as {
        username: string;
        email: string;
        isAdmin: boolean;
        isinf: boolean;
        text: string;
        img: string;
      };
      console.log(data);
      return data.isinf;
    } catch (error) {
      return false;
    }
  }
  return false;
};
export { isUserLogedin, isUserAdmin, isUserInf, user };
