import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import baseurl from "../../util/axios";
import { col } from "./Col";
import Expand from "./expand";
import { Button } from "@mui/material";
import "./style.css";
import Modal from "./Modal";
import { useQuery } from "react-query";
interface ip {
  id: string;
  ip: string;
  userId: string;
}
interface UserType {
  email: string;
  username: string;
  isAdmin: boolean;
  id: string;
  points: number;
  isInfint: boolean;
  isActive: boolean;
  location: ip[];
}
const Accounts = () => {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => {
    setOpen(!open);
  };
  const { data, refetch, isLoading } = useQuery(
    ["user"],
    async () => {
      const res = await baseurl.get("/accounts");
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 2000,
    }
  );
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return (
    <div className="wrap overflow-x-scroll">
      <div className="header">
        <Button variant="contained" onClick={() => toggle()}>
          {" "}
          add new account
        </Button>
      </div>
      <DataTable
        title="Accounts"
        data={data}
        columns={col}
        expandableRows
        expandableRowsComponent={(d) =>
          Expand({ data: d.data, refetch: refetch })
        }
      />
      <Modal isOpen={open} toggole={toggle} refetch={refetch} />
    </div>
  );
};

export default Accounts;
