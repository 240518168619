import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button, Checkbox } from "@mui/material";
import { ExpanderComponentProps } from "react-data-table-component";
import baseurl from "../../util/axios";
import Upload from "../../components/upload";
import Select from "react-select";
const opt = [
  { value: "both", label: "both" },
  { value: "carfax", label: "carfax" },
  { value: "epic", label: "epic" },
];
interface UserType {
  email: string;
  username: string;
  isAdmin: boolean;
  id: string;
  points: number;
  isInfint: boolean;
  text: string;
  img: string;
  isActive: boolean;
  password?: string;
  type: "carfax" | "epic" | "both";
  epicInfi: boolean;
  epicpoints: number | null;
}
interface prop extends ExpanderComponentProps<UserType> {
  refetch?: any;
}

const Expand = ({ data, refetch }: prop) => {
  const [img, setimg] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { ...data, password: "" },
  });
  const submit = async (user: UserType) => {
    if (img.length > 0) {
      const formdata = new FormData();
      formdata.append("image", img[0]);
      if (user.password && user.password.length === 0) {
        delete user.password;
      }
      console.log(user);
      Object.keys(user).forEach((a) => {
        if (a !== "id") {
          formdata.append(a, user[a as keyof typeof user] as string);
        }
      });
      await baseurl.put(`/user/${data.id}`, formdata);
    } else {
      console.log(user);

      await baseurl.put(`/user/${data.id}`, {
        email: user.email,
        username: user.username,
        isAdmin: data.isAdmin,
        isInfint: user.isInfint,
        text: user.text,
        points: user.points,
        password:
          user.password && user.password.length === 0
            ? undefined
            : user.password,
        isActive: user.isActive,
        epicpoints: user.epicpoints,
        epicInfi: user.epicInfi,
        type: user.type,
      });
    }
    refetch();
  };
  const DeleteUSer = async () => {
    await baseurl.delete(`/user/${data.id}`);
    refetch();
  };
  return (
    <form onSubmit={handleSubmit(submit)} className="overflow-x-auto">
      <div className="expand gap-x-3">
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField {...field} variant="outlined" label="username" />
          )}
        />
        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <TextField {...field} variant="outlined" label="name" />
          )}
        />
        <Controller
          control={control}
          name="text"
          render={({ field }) => (
            <TextField {...field} variant="outlined" label="label" />
          )}
        />
        <Controller
          control={control}
          name="points"
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              type="number"
              label="points"
            />
          )}
        />
        <Controller
          control={control}
          name="epicpoints"
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              type="number"
              label="epicpoints"
            />
          )}
        />
        <Select
          options={opt}
          onChange={(a) => {
            if (a) {
              setValue("type", a.value as "carfax" | "epic" | "both");
            }
          }}
          defaultValue={opt.find((x) => x.value === data.type)}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField {...field} variant="outlined" label="password" />
          )}
        />

        <Controller
          control={control}
          name="isInfint"
          render={({ field: { onChange, value, name, onBlur, ref } }) => (
            <Checkbox
              name={name}
              onChange={onChange}
              ref={ref}
              checked={value}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="epicInfi"
          render={({ field: { onChange, value, name, onBlur, ref } }) => (
            <Checkbox
              name={name}
              onChange={onChange}
              ref={ref}
              checked={value}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="isActive"
          render={({ field: { onChange, value, name, onBlur, ref } }) => (
            <Checkbox
              name={name}
              onChange={onChange}
              ref={ref}
              checked={value}
              onBlur={onBlur}
            />
          )}
        />
        <Button type="button" onClick={() => setOpen(true)} variant="outlined">
          add image
        </Button>
        <Button type="submit" variant="outlined">
          save
        </Button>
        <Button onClick={() => DeleteUSer()} variant="outlined">
          Delete
        </Button>
        <Upload
          files={img}
          setFiles={setimg}
          open={open}
          toggole={() => setOpen(!open)}
        />
      </div>
    </form>
  );
};

export default Expand;
