import React from "react";
import "./style.css";
import { TextField, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import baseurl from "../../util/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { user } from "../../util/isUserLogedin";
const INIT = {
  password: "",
  email: "",
};
const Login = () => {
  const nav = useNavigate();
  const { control, handleSubmit } = useForm({ defaultValues: INIT });
  const { i18n, t } = useTranslation();
  const onsumbit = async (data: { email: string; password: string }) => {
    try {
      const res = await baseurl.post("/auth", data);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("id", res.data.id);
      if (res.status === 200) {
        toast.success(
          i18n.language === "en"
            ? `${t("welcome")} ${user()?.username} `
            : `${user()?.username} ${t("welcome")}`,
          {
            position: "top-right",
          }
        );
        nav("/");
      } else if (res.status === 204) {
        toast.error("account is not active", {
          position: "top-right",
        });
      }
      window.location.replace("/");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="Cont">
      <form onSubmit={handleSubmit(onsumbit)}>
        <div className="loginCard">
          <div className="head">
            <h1>login</h1>
          </div>
          <div className="input">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField variant="outlined" label="email" {...field} />
              )}
            />

            <br />
            <br />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="passowrd"
                  type={"password"}
                  {...field}
                />
              )}
            />
          </div>
          <Button variant="outlined" type="submit">
            {" "}
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
