const alphabet: { [key: string]: string } = {
  A: "Z",
  a: "Z",
  B: "W",
  b: "W",
  c: "D",
  C: "D",
  d: "X",
  D: "X",
  e: "Y",
  E: "Y",
  f: "G",
  F: "G",
  g: "A",
  G: "A",
  h: "V",
  H: "V",
  i: "B",
  I: "B",
  j: "H",
  J: "H",
  k: "M",
  K: "M",
  L: "U",
  l: "U",
  M: "O",
  m: "O",
  N: "P",
  n: "P",
  O: "N",
  o: "N",
  P: "T",
  p: "T",
  Q: "R",
  q: "R",
  R: "C",
  r: "C",
  S: "J",
  s: "J",
  T: "L",
  t: "L",
  U: "F",
  u: "F",
  V: "S",
  v: "S",
  W: "K",
  w: "K",
  X: "I",
  x: "I",
  Y: "E",
  y: "E",
  Z: "Q",
  z: "Q",
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "0": "0",
};

const hash = (text: string): string => {
  const hashed = text.split("").reduce((prev, curr) => {
    return prev.concat(alphabet[curr]);
  }, "");

  return hashed;
};

export default hash;
