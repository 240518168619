import React, { forwardRef, ReactChildren } from "react";
interface prop {
  children?: React.ReactNode | React.ReactNode[];
}
const DropDown = forwardRef<HTMLDivElement, prop>(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className="absolute  bg-primary-ligh rounded-lg shadow-sm px-2 py-2 w-52 top-16 translate-x-[38%] z-50"
    >
      {children}
    </div>
  );
});

export default DropDown;
