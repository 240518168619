import React from "react";
interface prop {
  children?: React.ReactNode | React.ReactNode[];
}
const DropItem = ({ children }: prop) => {
  return (
    <div className="px-2 py-2 mb-2 rounded-md shadow-sm bg-primary-dark">
      {children}
    </div>
  );
};

export default DropItem;
