import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import Router from "./Router/Router";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";
const lan = localStorage.getItem("lan") || "en";
i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: lan, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    backend: {
      loadpath: "/assets/data/locales/{{lng}}.json",
    },

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router />
      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>
);
