import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
  LogarithmicScale,
  TimeSeriesScale,
} from "chart.js/auto";
import { useQuery } from "react-query";
import baseurl from "../../util/axios";
import "chartjs-adapter-date-fns";
import DataTable, { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
ChartJS.register(
  LinearScale,
  TimeScale,
  LogarithmicScale,
  Tooltip,
  Legend,
  TimeSeriesScale
);

const Dashboard = () => {
  const nav = useNavigate();
  const { data, isLoading } = useQuery(
    ["status"],
    async () => {
      const data = await baseurl.get("/status/all");
      return data.data;
    },
    {
      staleTime: 10000,
    }
  );
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  const openAcc = (user: user) => {
    nav(`/dashboard/${user.id}`);
  };
  return (
    <div className="">
      <DataTable columns={col} onRowClicked={openAcc} data={data} />
      <div className="pl-4 mt-2">
        total pdfs :
        <span className="pt-1 pl-1 pb-1 pr-3 border-2 border-gray-500 rounded-sm text-center ">
          {" "}
          &nbsp;
          {(data as user[]).reduce((a, o) => {
            return a + o.pdfs.length;
          }, 0)}
        </span>
      </div>
    </div>
  );
};
interface user {
  TotalPdf: number;
  email: string;
  id: string;
  img: string;
  isActive: boolean;
  isAdmin: boolean;
  isInfint: boolean;
  isOnline: boolean;
  lastAction: string;
  password: string;
  points: number;
  text: string;
  updatedAt: string;
  username: string;
  _count: { history: number; location: number; pdfs: number };
  pdfs: { id: string; usersId: string; vin: string; CreatedAt: string }[];
  location: { PdfPerIp: number }[];
}
const col: TableColumn<user>[] = [
  {
    name: "today pdfs",
    selector: (row) => row.pdfs.length,
  },
  { name: "number of active accounts", selector: (row) => row.location.length },
  {
    id: "id",
    name: "username",

    cell: (row) => (
      <span className="text-right w-1/4 font-bold">{row.username}</span>
    ),
  },
];

export default Dashboard;
