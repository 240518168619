import React from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import baseurl from "../../util/axios";

const OneUser = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["user", id],
    async () => {
      const res = await baseurl.get(`/status/${id}`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60,
    }
  );
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  console.log(data);
  if (data) {
    document.title = data.user.username;
  }
  return (
    <div className="flex p-4 text-xl">
      <div className="flex text-left flex-col w-80 rounded-md mr-4 items-center  shadow-[0_0px_24px_rgba(0,0,0,0.25)] p-4">
        <h4>open accounts</h4>
        {data.locations.map((d: any) => {
          return (
            <span className="text-left w-[90%]">
              <strong>{d.pdfLoc.length}</strong> pdf from {d.ip}
            </span>
          );
        })}
      </div>
      <div className="flex flex-wrap gap-2 w-80">
        <div className="shadow-[0_0px_24px_rgba(0,0,0,0.25)] rounded-md flex flex-col items-center p-6 h-32">
          <h4>status</h4>
          <div>{`${data.user.isOnline ? "online" : "offline"}`}</div>
        </div>
        <div className="shadow-[0_0px_24px_rgba(0,0,0,0.25)] rounded-md flex flex-col items-center p-6 h-32">
          <h4>pdf today</h4>
          <div>{data.user.pdfs.length}</div>
        </div>
        <div className="shadow-[0_0px_24px_rgba(0,0,0,0.25)] rounded-md flex flex-col items-center p-6 h-32 w-full">
          <h4>overall pdfs</h4>
          <div>{data.user._count.pdfs}</div>
        </div>
        <div className="p-2 shadow-[0_0px_24px_rgba(0,0,0,0.25)] rounded-md h-72 ">
          <h4>pdf per day</h4>
          <Line
            data={{
              datasets: [
                {
                  data: data.pdfStatus,
                  parsing: { xAxisKey: "createdAt", yAxisKey: "_count" },
                },
              ],
              labels: data.pdfStatus.map((a: any) => a.createdAt),
            }}
            options={{
              plugins: {
                legend: {
                  //watch out: new syntax in v3.2.0, `legend` within `plugins`
                  display: false,
                },
                title: {
                  //watch out: new syntax in v3.2.0, `title` within `plugins`
                  display: false,
                },
              },
              scales: {
                x: {
                  type: "time",

                  time: {
                    unit: "day",
                    minUnit: "day",
                  },
                  ticks: {
                    // Edit: added this to avoid overlapping - thanks for comment
                    minRotation: 85, // <-- just try any number
                    maxRotation: 90, // <-- just try any number
                    autoSkip: false,
                  },
                },
                y: {
                  type: "linear",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="flex text-left flex-col w-[45rem] rounded-md mx-4 items-center  shadow-[0_0px_24px_rgba(0,0,0,0.25)] p-4 overflow-y-scroll h-96">
        <h4>vhrs on ip</h4>
        {data.locations.map((d: any) => {
          return d.pdfLoc.map((s: any) => {
            return (
              <span className="text-left w-[90%]">
                <strong>{s.vin}</strong> type {s.type} pdf from {d.ip}
              </span>
            );
          });
        })}
      </div>
    </div>
  );
};

export default OneUser;
