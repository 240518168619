import React, { useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button, Checkbox } from "@mui/material";
import baseurl from "../../util/axios";
import Upload from "../../components/upload";
import Selecr from "react-select";
interface modaltype {
  isOpen: boolean;
  toggole: () => void;
  refetch: () => void;
}
interface dataType {
  password: string;
  email: string;

  isActive: boolean;
}
const INIT = {
  password: "",
  email: "",
  isActive: false,
};
const opt = [
  { value: "both", label: "both" },
  { value: "carfax", label: "carfax" },
  { value: "epic", label: "epic" },
];
const Modal = ({ isOpen, toggole, refetch }: modaltype) => {
  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: INIT,
  });

  const submit = async (data: dataType) => {
    await baseurl.post("/accounts/create", data);

    refetch();
  };
  return (
    <Offcanvas direction="end" isOpen={isOpen} toggle={toggole}>
      <OffcanvasHeader toggle={toggole}>new user</OffcanvasHeader>
      <OffcanvasBody>
        <form onSubmit={handleSubmit(submit)} className="form">
          <div className="input">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="username"
                  variant="outlined"
                  type={"text"}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="password"
                  variant="outlined"
                  type="password"
                />
              )}
            />

            <div>
              <label htmlFor="isActive">is Active </label>
              <Controller
                control={control}
                name="isActive"
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <Checkbox
                    id="isActive"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    checked={value}
                  />
                )}
              />
            </div>

            <Button type="submit" variant="outlined">
              save
            </Button>
          </div>
        </form>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default Modal;
