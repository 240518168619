import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button, Checkbox } from "@mui/material";
import { ExpanderComponentProps } from "react-data-table-component";
import baseurl from "../../util/axios";

interface UserType {
  email: string;

  id: string;

  password: string;
  isActive: boolean;
}
interface prop extends ExpanderComponentProps<UserType> {
  refetch?: any;
}

const Expand = ({ data, refetch }: prop) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { ...data, password: "" },
  });
  const submit = async (user: UserType) => {
    await baseurl.put(`/accounts/${data.id}`, {
      email: user.email,

      password:
        user.password && user.password.length === 0 ? undefined : user.password,
      isActive: user.isActive,
    });
    refetch();
  };

  const DeleteUSer = async () => {
    await baseurl.delete(`/accounts/${data.id}`);
    refetch();
  };
  return (
    <form onSubmit={handleSubmit(submit)} className="overflow-x-auto">
      <div className="expand gap-x-3">
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField {...field} variant="outlined" label="email" />
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField {...field} variant="outlined" label="password" />
          )}
        />

        <Controller
          control={control}
          name="isActive"
          render={({ field: { onChange, value, name, onBlur, ref } }) => (
            <Checkbox
              name={name}
              onChange={onChange}
              ref={ref}
              checked={value}
              onBlur={onBlur}
            />
          )}
        />

        <Button type="submit" variant="outlined">
          save
        </Button>
        <Button onClick={() => DeleteUSer()} variant="outlined">
          Delete
        </Button>
      </div>
    </form>
  );
};

export default Expand;
