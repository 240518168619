import React, { useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button, Checkbox } from "@mui/material";
import baseurl from "../../util/axios";
import Upload from "../../components/upload";
import Selecr from "react-select";
interface modaltype {
  isOpen: boolean;
  toggole: () => void;
  refetch: () => void;
}
interface dataType {
  password: string;
  email: string;
  username: string;
  isAdmin: boolean;
  points: number;
  isInfint: boolean;
  text: string;
}
const INIT = {
  password: "",
  email: "",
  username: "",
  isAdmin: false,
  isInfint: false,
  points: 0,
  text: "",
  type: "carfax",
  epicpoints: 0,
  epicInfi: false,
};
const opt = [
  { value: "both", label: "both" },
  { value: "carfax", label: "carfax" },
  { value: "epic", label: "epic" },
];
const Modal = ({ isOpen, toggole, refetch }: modaltype) => {
  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: INIT,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<any>([]);
  const submit = async (data: dataType) => {
    if (file.length > 0) {
      const formdata = new FormData();
      formdata.append("image", file[0]);
      Object.keys(data).forEach((a) => {
        if (a !== "id") {
          formdata.append(a, data[a as keyof typeof data] as string);
        }
      });
      await baseurl.post("/user/create", formdata);
    } else {
      await baseurl.post("/user/create", data);
    }
    refetch();
  };
  return (
    <Offcanvas direction="end" isOpen={isOpen} toggle={toggole}>
      <OffcanvasHeader toggle={toggole}>new user</OffcanvasHeader>
      <OffcanvasBody>
        <form onSubmit={handleSubmit(submit)} className="form">
          <div className="input">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <TextField {...field} label="name" variant="outlined" />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="username"
                  variant="outlined"
                  type={"text"}
                />
              )}
            />
            <Controller
              control={control}
              name="text"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="label"
                  variant="outlined"
                  type={"text"}
                />
              )}
            />
            <Controller
              control={control}
              name="points"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="points"
                  variant="outlined"
                  type="number"
                />
              )}
            />
            <Controller
              control={control}
              name="epicpoints"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="epic points"
                  variant="outlined"
                  type="number"
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="password"
                  variant="outlined"
                  type="password"
                />
              )}
            />
            <Selecr
              options={opt}
              className="w-[223px]"
              onChange={(a) => {
                if (a) {
                  setValue("type", a.value);
                }
              }}
              defaultValue={opt.find((x) => x.value === getValues("type"))}
            />
            <div>
              <label htmlFor="isInfint">is Infint </label>
              <Controller
                control={control}
                name="isInfint"
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <Checkbox
                    id="isInfint"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    checked={value}
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor="epicInfi">is Infint epic</label>
              <Controller
                control={control}
                name="epicInfi"
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <Checkbox
                    id="epicInfi"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    ref={ref}
                    checked={value}
                  />
                )}
              />
            </div>
            <Button
              type="button"
              onClick={() => setOpen(true)}
              variant="outlined"
            >
              add Image
            </Button>
            <Button type="submit" variant="outlined">
              save
            </Button>
          </div>
        </form>
      </OffcanvasBody>
      <Upload
        files={file}
        setFiles={setFile}
        open={open}
        toggole={() => setOpen(!open)}
      />
    </Offcanvas>
  );
};

export default Modal;
