import { TableColumn } from "react-data-table-component";
interface ip {
  id: string;
  ip: string;
  userId: string;
}
interface UserType {
  email: string;
  username: string;
  isAdmin: boolean;
  id: string;
  points: number;
  isInfint: boolean;
  text: string;
  img: string;
  isActive: boolean;
  location: ip[];
  type: "carfax" | "epic" | "both";
  epicInfi: boolean;
  epicpoints: number | null;
}
export const col: TableColumn<UserType>[] = [
  {
    name: "username",
    selector: (row) => row.email,
  },
  {
    name: "name",
    selector: (row) => row.username,
  },
  {
    name: "label",
    selector: (row) => row.text,
  },

  {
    name: "points",
    selector: (row) => `${row.isInfint ? "infinity" : row.points}`,
  },
  { name: "type", selector: (row) => row.type },
  {
    name: "isInfint",
    selector: (row) => `${row.isInfint}`,
  },
  {
    name: "is Active",
    selector: (row) => `${row.isActive}`,
  },
  {
    name: "epic points",
    selector: (row) =>
      `${
        row.epicInfi
          ? "infinity"
          : row.epicpoints
          ? row.epicpoints
          : "not epic sub"
      }`,
  },
  {
    name: "epic infint",
    selector: (row) => `${row.epicInfi}`,
  },
];
