import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import {
  TextField,
  Button,
  Alert,
  Popover,
  Switch,
  ButtonBase,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { BrowserRouter, useNavigate } from "react-router-dom";
import crypto from "crypto-js";
import hash from "./util/hash";
import baseurl from "./util/axios";
import { isUserAdmin, user } from "./util/isUserLogedin";
import DropDown from "./components/DropDown";
import DropItem from "./components/DropItem";
import moment from "moment";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
import axios from "axios";
interface hisType {
  createdAt: string;
  vin: String;
  id: String;
}
const Options = [
  {
    value: "ar",
    label: "العربية",
  },
  {
    value: "en",
    label: "english",
  },
];
function App() {
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const id = localStorage.getItem("id");
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<boolean>(false);
  const {
    data: points,
    refetch,
    isLoading,
  } = useQuery(
    ["points", id],
    async () => {
      const data = await baseurl.get("/points", { params: { id } });
      console.log(data.data);
      return data.data.points;
    },
    {
      refetchOnWindowFocus: true,
    }
  );
  const { data: profile, isLoading: prof } = useQuery(
    ["profile"],
    async () => {
      const data = await baseurl.get("/profile");
      if (data.data.type === "epic") {
        setType(true);
      } else {
        setType(false);
      }
      return data.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 3000000,
    }
  );
  const { data: active, isLoading: Ac } = useQuery("active", async () => {
    const data = await baseurl.get("/active", { params: { id } });
    if (!data.data.active) {
      logout();
    }
    return data.data.active;
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    getValues,
    resetField,
  } = useForm({
    defaultValues: { vin: "", q: "" },
  });

  const { data: his } = useQuery<hisType[], null>(
    ["history", watch("q")],
    async () => {
      const data = await baseurl.get("/history", {
        params: { id, q: getValues("q") },
      });

      return data.data.data;
    }
  );
  const logout = () => {
    nav("/login");
    localStorage.removeItem("token");
    window.location.reload();
  };

  useOutsideAlerter(ref, setOpen, open);
  useEffect(() => {
    if (profile?.type === "both" || profile?.type === "carfax") {
      if (!profile?.isInfint) {
        if (profile?.points > 0) {
          toast.info(`you have ${profile?.points}`, {
            position: "bottom-left",
            autoClose: false,
          });
        } else if (profile?.points === 0) {
          toast.error("you dont have any points left", {
            position: "bottom-left",
            autoClose: false,
          });
        }
      }
    }
    if (profile?.type === "both" || profile?.type === "epic") {
      if (!profile?.epicInfi) {
        if (profile?.epicpoints > 0) {
          toast.info(`you have ${profile?.epicpoints}`, {
            position: "bottom-left",
            autoClose: false,
          });
        } else if (profile?.epicpoints === 0) {
          toast.error("you dont have any points left", {
            position: "bottom-left",
            autoClose: false,
          });
        }
      }
    }
  }, [profile]);
  useEffect(() => {
    document.documentElement.style.setProperty(
      "---color",
      type ? "0,112,162" : "161,94,54"
    );
  }, [type]);
  const nav = useNavigate();
  const find = async (data: { vin: string }) => {
    const id = localStorage.getItem("id");
    if (data.vin.trim().length === 17 || data.vin.trim().length === 18) {
      console.log(profile);
      const hashed = hash(data.vin.replaceAll(/\s/g, ""));
      if (!isLoading && !Ac) {
        if (!active) {
          toast.error("account is not active", {
            position: "top-left",
          });
          logout();
        } else {
          if (0 >= points) {
            alert("not enough points");
          } else {
            baseurl.post(
              "/history/add",
              {},
              {
                params: {
                  vin: data.vin,
                  id,
                },
              }
            );
            baseurl.put("/location", {
              id,
            });
            refetch({});

            // win?.fetch(`https://carvin17.com/vhrs?vin=${hashed}`, {
            //   method: "GET",
            //   headers: {
            //     id: id ? id : "no",
            //     type: "new",
            //   },
            // });
            const win = window;
            if (type) {
              win.fetch(`http://3.21.235.156:5000/epicvin?vin=${data.vin}`, {
                headers: {
                  id: id ? id : "no",
                },
              });
              if (autod) {
                win.open(
                  `http://3.21.235.156:5000/epicvin?vin=${data.vin}&download=yes`
                );
              }
              win.open(`http://3.21.235.156:5000/epicvin?vin=${data.vin}`);
            } else {
              win.fetch(
                `https://api.carvin17.com/vhrs?vin=${hashed}${
                  profile?.isHtml ? "&isHtml=true" : ""
                }`,
                {
                  headers: {
                    id: id ? id : "no",
                  },
                }
              );
              if (autod) {
                win.open(
                  `https://api.carvin17.com/vhrs?vin=${hashed}&download=yes${
                    profile?.isHtml ? "&isHtml=true" : ""
                  }`
                );
              }
              win.open(
                `https://api.carvin17.com/vhrs?vin=${hashed}${
                  profile?.isHtml ? "&isHtml=true" : ""
                }`
              );
            }
            resetField("vin", { defaultValue: "" });
          }
        }
      } else {
        console.log("no");
      }
    }
  };
  const find2 = async (data: { vin: string }) => {
    const id = localStorage.getItem("id");
    const hashed = hash(data.vin.replaceAll(/\s/g, ""));

    window.open(`https://api.carvin17.com/vhrs?vin=${hashed}`);
  };

  const [autop, setautop] = useState(
    JSON.parse(localStorage.getItem("autop") as string) || false
  );
  const [autof, setautof] = useState(
    JSON.parse(localStorage.getItem("autof") as string) || false
  );
  const [autod, setautod] = useState(
    JSON.parse(localStorage.getItem("autod") as string) || false
  );
  useEffect(() => {
    localStorage.setItem("autop", autop);
    localStorage.setItem("autof", autof);
  }, [autof, autop]);
  if (prof) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return (
    <div className="h-screen p-4 overflow-y-scroll bg-primary-dark">
      <div className="flex min-w-[350px] items-center h-16 px-2 rounded-lg bg-primary-ligh ">
        <div className="relative flex items-center justify-center font-bold text-white rounded-full w-14 bg-primary-dark aspect-square">
          <div
            className="flex items-center justify-center w-full h-full cursor-pointer "
            onClick={() => setOpen(!open)}
          >
            {profile?.img ? (
              <img
                src={`https://dczl2vty8e5ck.cloudfront.net/${profile?.img}`}
                className="rounded-full"
                alt="user"
              />
            ) : (
              <h4 className="pt-1">
                {" "}
                {profile?.text
                  ? profile?.text?.charAt(0)?.toUpperCase()
                  : profile?.username?.charAt(0)?.toUpperCase()}
              </h4>
            )}
          </div>
          {open ? (
            <DropDown ref={ref}>
              <DropItem>
                <div className="flex items-center justify-between">
                  <label htmlFor="autp">{t("autop")}</label>
                  <Switch
                    onClick={(e) => {
                      setautop((e.target as HTMLInputElement).checked);
                    }}
                    checked={autop}
                  />
                </div>
              </DropItem>
              <DropItem>
                <div className="flex items-center justify-between">
                  <label htmlFor="autp">{t("autof")}</label>
                  <Switch
                    onClick={(e) => {
                      setautof((e.target as HTMLInputElement).checked);
                    }}
                    checked={autof}
                  />
                </div>
              </DropItem>
              <DropItem>
                <div className="flex items-center justify-between">
                  <label htmlFor="autd">{t("autod")}</label>
                  <Switch
                    onClick={(e) => {
                      setautod((e.target as HTMLInputElement).checked);
                    }}
                    checked={autod}
                  />
                </div>
              </DropItem>
              <DropItem>
                <div className="flex items-center justify-between">
                  <ReactSelect
                    className="w-full text-white"
                    options={Options}
                    value={Options.find((a) => a.value === i18n.language)}
                    onChange={(e) => {
                      if (e) {
                        localStorage.setItem("lan", e.value);
                        i18n.changeLanguage(e?.value);
                      }
                    }}
                    styles={{
                      singleValue: (pr) => ({
                        ...pr,
                        color: "#ffffff",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral0: "#262626",
                        neutral5: "#ffffff",
                        neutral70: "#ffffff",
                        neutral90: "#ffffff",
                        primary: "#8C8C8C",
                        primary25: "#404040",
                        primary50: "#8C8C8C",
                      },
                    })}
                  />
                </div>
              </DropItem>
              <DropItem>
                <div className="flex items-center justify-between">
                  <Button
                    onClick={() => logout()}
                    className="w-full"
                    variant="outlined"
                    color="error"
                  >
                    {t("logout")}
                  </Button>
                </div>
              </DropItem>
            </DropDown>
          ) : null}
        </div>
        {profile?.text ? (
          <h4 className="pt-2 ml-2">{profile?.text}</h4>
        ) : (
          <h4 className="pt-2 ml-2">{profile?.username}</h4>
        )}

        <h4
          onClick={() => window.location.reload()}
          className="ml-auto mr-[5.5rem] font-bold leading-none pt-2 cursor-pointer"
        >
          CARVIN17
        </h4>
      </div>
      <div className="flex flex-col mt-4 min-w-[480px] h-4/5 gap-y-4 md:gap-x-4 md:flex-row">
        <div className="flex flex-col w-full md:w-4/5 gap-y-4">
          <div className="bg-primary-ligh h-24 md:h-[15%] rounded-md flex items-center justify-center">
            <h4 className="font-bold font-Noto">
              {`  مرحبا، ` + profile?.username}
            </h4>
          </div>
          <div
            className={`bg-primary-ligh md:h-[85%] h-60 rounded-md flex ${
              profile.type === "both" ? "flex-col" : ""
            } items-center justify-center`}
          >
            {profile.type === "both" ? (
              <div className="mb-12 md:-mt-36 w-20 h-10 bg-primary-dark rounded-full relative ">
                <label htmlFor="check">
                  <input
                    checked={type}
                    onChange={(e) => setType(e.target.checked)}
                    type="checkbox"
                    id="check"
                    className="sr-only peer"
                  />
                  <span className="w-2/5 cursor-pointer h-4/5 bg-[url('../public/c.jpeg')] bg-cover bg-no-repeat absolute rounded-full left-1 top-1 peer-checked:bg-[url('../public/e.jpeg')] peer-checked:left-11 transition-all"></span>
                </label>
              </div>
            ) : null}
            <div className="flex items-center h-16 rounded-md bg-primary-dark w-96 hover:[&_h3]:opacity-80 relative z-10 ">
              <div className="p"></div>
              <div className="flex items-center h-16 rounded-md bg-primary-dark w-96 hover:[&_h3]:opacity-80 relative z-10">
                <div className="relative w-4/5 h-full py-3 pl-4 text-lg text-gray-300 rounded-md bg-primary-neu z-10">
                  <input
                    type="text"
                    id="vin"
                    className="w-full h-full bg-transparent focus-visible:border-transparent focus-visible:outline-none "
                    onClick={async () => {
                      const text = await navigator.clipboard.readText();
                      if (
                        (text.trim().length === 17 ||
                          text.trim().length === 18) &&
                        autop
                      ) {
                        setValue("vin", text);
                      }
                      if (
                        (text.trim().length === 17 ||
                          text.trim().length === 18) &&
                        autof
                      ) {
                        await find({ vin: text });
                      }
                    }}
                    onKeyDown={(a) => {
                      if (a.key === "Enter") {
                        if (
                          getValues("vin").trim().length === 17 ||
                          getValues("vin").trim().length === 18
                        )
                          find({ vin: getValues("vin") });
                      }
                    }}
                    {...register("vin")}
                  />
                  <label
                    htmlFor="vin"
                    className={`absolute pointer-events-none left-4 ${
                      i18n.language === "ar" ? "pl-36" : ""
                    } ${watch("vin").length > 0 ? "hidden" : ""} bottom-4`}
                  >
                    {" "}
                    {t("EnterVin")}
                  </label>
                </div>
                <h4
                  className="p-4 pb-3 leading-none text-white cursor-pointer"
                  onClick={handleSubmit(find)}
                >
                  {t("find")}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/6 p-2 min-w-[300px] rounded-lg bg-primary-ligh">
          <div className="relative w-full h-16">
            <input
              type="text"
              id="s"
              placeholder={t("search") as string}
              onChange={(e) => setValue("q", e.target.value)}
              className={`w-full h-12 px-2 rounded-md ${
                i18n.language === "ar"
                  ? "placeholder:absolute placeholder:right-2 placeholder:top-3 "
                  : ""
              }  bg-primary-dark text-primary-ligh focus-visible:border-transparent focus-visible:outline-none`}
            />
          </div>
          <div className="bg-primary-dark  rounded-md h-[86%] text-primary-ligh py-2 flex items-center justify-center">
            <ul className="flex flex-col items-center w-full h-full px-2 mt-8 overflow-x-hidden gap-y-4 ">
              {his?.map((i, s) => {
                return (
                  <li
                    onClick={async () => await find2({ vin: i.vin.toString() })}
                    key={s}
                    className="relative block w-full h-16 text-white bg-black rounded-xl"
                  >
                    <div className="flex items-center justify-end px-2 pt-2">
                      <span className="text-xs ">
                        {moment(i.createdAt, "YYYY-MM-DD").format("l")}
                      </span>
                    </div>
                    <div className="flex items-center justify-center ">
                      <h5 className="text-lg font-normal">{i.vin}</h5>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

/* */
// <div className="wraper">
//   <form className="Cont" onSubmit={handleSubmit(find)}>
//     <div className="c">
//       {user()?.isinf ? null : (
//         <Alert severity="info" className="points">
//           you have {points} points
//         </Alert>
//       )}
//       <Controller
//         control={control}
//         name="vin"
//         render={({ field }) => (
//           <TextField {...field} variant="outlined" label="enter vin" />
//         )}
//       />
//       <Button variant="outlined" type="submit">
//         find
//       </Button>
//       <div className="b">
//         <Button variant="outlined" onClick={() => logout()}>
//           logout
//         </Button>
//         <Button variant="outlined" onClick={() => toggole()}>
//           history
//         </Button>
//       </div>
//     </div>
//   </form>
//   {showH ? (
//     <div className="history">
//       {his?.map((item, i) => {
//         return (
//           <div
//             key={i}
//             className="item"
//             onClick={() => find2({ vin: item.vin.toString() })}
//           >
//             {item.vin}
//           </div>
//         );
//       })}
//     </div>
//   ) : null}{" "}
// </div>
function useOutsideAlerter(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isopen: boolean
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      console.log("out");
      if (ref.current) {
        console.log(ref.current.contains(event.target));
      }
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
