/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "./style.css";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100px",
  height: "100%",
};
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
const Upload = ({
  open,
  toggole,
  files,
  setFiles,
}: {
  open: boolean;
  toggole: () => void;
  files: any;
  setFiles: React.Dispatch<any>;
}) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, isDragAccept, isDragReject]
  );
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const thumbs = files.map((file: any) => (
    <div className="thumb" key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="preivew"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <Modal isOpen={open} toggle={toggole} backdrop centered fullscreen="xl">
      <ModalHeader toggle={toggole}>upload image</ModalHeader>
      <ModalBody>
        <div className="container">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside className="thumbsContainer">{thumbs}</aside>
          <br />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ marginRight: "20px" }}
          color="warning"
          variant="contained"
          onClick={() => {
            toggole();
            setFiles([]);
          }}
        >
          cancel
        </Button>
        <Button color="warning" variant="outlined" onClick={() => toggole()}>
          save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Upload;
