import {
  BrowserRouter as AppRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from "../App";
import Dashboard from "../pages/dashboard";
import OneUser from "../pages/dashboard/user";
import Login from "../pages/Login";
import User from "../pages/User";
import Index from "../pages/vhrs/Index";
import { isUserAdmin, isUserLogedin } from "../util/isUserLogedin";
import Accounts from "../pages/accounts";
const Router = () => {
  return (
    <AppRouter>
      <Routes>
        <Route
          path="/"
          element={
            isUserLogedin() ? <App /> : <Navigate to={"/login"} replace />
          }
        />
        <Route
          path="/user"
          element={isUserAdmin() ? <User /> : <Navigate to={"/"} replace />}
        />
        <Route
          path="/accounts"
          element={isUserAdmin() ? <Accounts /> : <Navigate to={"/"} replace />}
        />
        <Route path="/dashboard">
          <Route
            index
            element={
              isUserAdmin() ? <Dashboard /> : <Navigate to={"/"} replace />
            }
          />
          <Route
            path="/dashboard/:id"
            element={
              isUserAdmin() ? <OneUser /> : <Navigate to={"/"} replace />
            }
          />
        </Route>
        <Route
          path="/login"
          element={isUserLogedin() ? <Navigate to={"/"} replace /> : <Login />}
        />
        {/* <Route
          path="/vhrs/:vin"
          element={
            isUserLogedin() ? <Index /> : <Navigate to={"/login"} replace />
          }
        /> */}
      </Routes>
    </AppRouter>
  );
};

export default Router;
